header .MuiInputBase-root {
  color: rgba(255,255,255,.87);
}

header .MuiInput-underline:before {
  border-bottom: 1px solid rgba(255,255,255,.42);
}

header .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgba(255,255,255,.87);
}

header .MuiInput-underline:after {
  border-bottom: 2px solid white;
}

header .MuiInputBase-input {
  color: white;
}

header {
  background-color: transparent!important;
  box-shadow: none!important;
}
