html,body {
  max-width: 100%;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif!important;
}

header a {
  color: inherit;
  text-decoration: none;
}
header a:hover {
  color: inherit;
  text-decoration: none;
  /*text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;*/
  text-shadow: 0 0 1rem white;
}

footer a {
  color: inherit;
  text-decoration: none;
}
footer a:hover {
  color: inherit;
  text-decoration: none;
}

.main-content {
  max-width: 912px;
  padding-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.bg {
  width: 100%;
  height: 312px;
  background: indigo;
  background-size: cover!important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.app-icon {
  border-radius: 1rem;
  width: 80px;
  height: 80px;
}

.app-gallery {
  display: inline-flex;
}
.app-gallery > button {
  padding: 0;
  border: none;
}
.app-gallery > button > img {
  width: 100%;
  border-radius: .5rem;
}
.app-gallery > img, .app-gallery > button {
  width: 25%;
  max-width: 125px;
  display: inline-block;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: .5rem;
}

.download-appstore-btn {
  margin-top: 25px;
  display: inline-block;
  background: url(/img/download-appstore.svg) no-repeat;
  background-size: contain;
  display: inline-block;
  padding: 18px 46px;
  width: 180px;
  height: 60px;
}

.preview-container {
  overflow: hidden;
}
.preview {
  -webkit-transform: skew(45deg, -30deg);
          transform: skew(45deg, -30deg);
  background-size: contain!important;
}
